<template>
  <div>
    <title-link title="投诉记录" :onClick="onCLickBack"></title-link>
    <div class="body">
      <van-pull-refresh v-model="isLoading" @refresh="pullRefreshList" style="padding-bottom:2rem">
        <van-list v-model="listLoading" :finished="listFinished"
            :finished-text="list.length > 0?'没有更多了': ''" @load="loadPage">
          <card style="padding:.3rem;line-height:.3rem" v-for="(feedback, idx) in list" :key="idx" @click.native="onDetailClick(feedback.id)">
            <template #content>
              <van-row style="margin-top:-.2rem">
                <van-col span="16" class="text-ellipsis">
                  <span class="list-title-text-normal">{{feedback.feedback_content == ''?'暂无评价':feedback.feedback_content}}</span>
                </van-col>
                <van-col span="8" class="text-right">
                  <van-tag round :type="feedback.approve_status == null? 'warning'
                    :feedback.approve_status == 1? 'primary': 'danger'"
                    :color="feedback.approve_status == 1?'rgba(64, 169, 255, 0.16)'
                      : feedback.approve_status == 2? '#f9f0f5': '#f5f5f5'"
                    :text-color="feedback.approve_status == 1?'#40a9ff'
                      :feedback.approve_status == 2? '#D52B4D' : '#666666'">
                      {{feedback.approve_status == null? '未处理': feedback.approve_status_text}}
                  </van-tag>
                </van-col>
              </van-row>
              <van-row style="color:#666666;margin-top:.1rem;">
                <van-col span="12">
                  <span class="list-info-text">
                    {{feedback.feedback_object.object}}
                  </span>
                </van-col>
                <van-col span="12" style="text-align:right">
                  <span class="list-info-text">
                    {{feedback.feedback_object.name}}
                  </span>
                </van-col>
              </van-row>
              <van-row style="color:#666666;">
                <van-col span="12" class="list-info-text">提交时间</van-col>
                <van-col span="12" style="text-align:right" class="list-info-text">
                  {{feedback.created_at}}
                </van-col>
              </van-row>
            </template>
          </card>
        </van-list>
        <van-empty description="暂无数据" v-if="list.length == 0 && !isLoading"/>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import titleLink from "@/views/components/TitleLink"
import api from "../../services/apis"
import Card from '../components/Card.vue'
export default {
  components: {titleLink, Card},
  data() {
    return {
      list: [],
      isLoading: false,
      listLoading: false,
      query: {
        page: 1,
        feedback_type:3
      },
      pagination: {
        current: 1,
        total_pages: 1,
        next_page: null
      }
    }
  },
  mounted() {
    this.refreshList()
  },
  computed: {
    listFinished() {
      return this.pagination.next_page == null
    }
  },
  methods: {
    onCLickBack() {
      this.$router.go(-1)
    },
    refreshList() {
      this.isLoading = true
      this.getTousu()
    },
    getTousu() {
      api.feedbacks(this.query).then(res => {
        this.isLoading = false
        this.listLoading = false
        if(res.status === 200) {
          res.data.forEach(element => {
            this.list.push(element)
          });
          this.pagination.current = res.current_page
          this.pagination.total_pages = res.total_pages
          this.pagination.next_page = res.next_page
        }
      })
    },
    loadPage() {
      this.listLoading = true
      this.query.page += 1
      this.refreshList()
    },
    onDetailClick(id) {
      this.$router.push(`/tousu/info/${id}`)
    },
    pullRefreshList() {
      this.query.page = 1
      this.list = []
      this.refreshList()
    }
  }
}
</script>

<style scoped>
.body {
  margin-top: .3rem;
  padding:.3rem .2rem 3rem .2rem;
  height:95vh;
  overflow: hidden;
  overflow-y: scroll;
}
</style>